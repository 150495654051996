<template>
  <v-container fluid>
    <v-form @submit.prevent="submitSearch" :disabled="isFetching || tableLoading">
      <v-row>
        <v-col cols="12">
          <v-btn
            depressed
            color="primary accent-4"
            class="primary m-auto white--text"
            :to="{ name: 'adminLeaveCreate' }"
            :disabled="isFetching || tableLoading"
            >新增假期申請</v-btn
          >
        </v-col>

        <v-col xl="6" lg="6" md="6" cols="12">
          <FormControl
            inputType="select"
            :inputValue.sync="searchStaffs"
            label="員工"
            :dense="true"
            :options="staffList"
            :disabled="isFetching || tableLoading"
          />
        </v-col>
        <v-col xl="4" lg="4" md="4" cols="12" class="d-flex align-center">
          <v-btn
            depressed
            color="success darken-4"
            class="mr-6 white--text"
            type="submit"
            :loading="isFetching || tableLoading"
            small
            >搜尋</v-btn
          >
          <v-btn depressed color="error" @click.prevent="clearSearch" :disabled="isFetching || tableLoading" small
            >清除</v-btn
          >
        </v-col>

        <!--   <v-col cols="12" md="2" class="pa-2 align-center justify-end d-flex" flat outlined tile>
            <div class="">
              <v-dialog max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    v-on="on"
                    v-bind="attrs"
                    small
                    color="secondary"
                    class="m2 white--text"
                    :loading="isFetching"
                    :disabled="isFetching"
                  >
                    <v-icon left color="white">{{ icons.mdiDownloadOutline }}</v-icon
                    >下載
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card class="py-3">
                    <v-card-text>
                      <div class="text-h6 pa-2">
                        <FormControl inputType="select" :inputValue.sync="download.date" placeHolder="日期" />
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        depressed
                        color="primary accent-4"
                        class="primary m-auto white--text"
                        block
                        @click="dialog.value = false"
                        >下載</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col> -->
      </v-row>
    </v-form>

    <v-row class="mt-6">
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'adminLeaveDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiDownloadOutline } from '@mdi/js'
import FormControl from '@/components/form/FormControl'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'AdminLeave',
  components: {
    FormControl,
    Datatable,
  },
  data() {
    return {
      isFetching: false,
      tableLoading: false,
      icons: {
        mdiDownloadOutline,
      },
      tableHeaders: [
        { text: '員工名稱', value: 'staff_name' },
        { text: '員工電郵', value: 'staff_email' },
        { text: '假期類別', value: 'absent_type' },
        { text: '請假日期', value: 'date' },
        { text: '種類', value: 'absent_time' },
        { text: '申請狀態', value: 'approve_status' },
        { text: '備註', value: 'remark' },
        {
          text: '',
          value: 'actionView',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
      searchStaffs: '',
      staffList: [],
      download: {
        date: '',
      },
    }
  },
  methods: {
    async getAbsentData() {
      if (!this.tableLoading && !this.isFetching) {
        this.tableLoading = true

        try {
          const payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            verify_token: this.getAdminToken(),
            join_staff: true,
          }

          if (this.$validate.DataValid(this.searchStaffs)) {
            payload.staff_id = parseInt(this.searchStaffs)
          }

          const result = await this.$XHR.api('cms_get_absent_list', payload)
          this.$func.log('-----Get Absent List-----')
          this.$func.log(result)

          this.formItemTotal = result.total

          const temp = []

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            const data = {
              id: resultData.id,
              absent_type: resultData.absent_type,
              absent_time: resultData.time,
              approve_status: resultData.approve_status,
              remark: resultData.remark,
              staff_name: '',
              staff_email: '',
            }

            if (resultData.start_date === resultData.end_date) {
              data.date = resultData.start_date
            } else {
              data.date = `${resultData.start_date} 至 ${resultData.end_date}`
            }

            if (this.$validate.DataValid(resultData.staff_data)) {
              data.staff_name = resultData.staff_data.name
              data.staff_email = resultData.staff_data.email
            }

            temp.push(data)
          }

          this.formData = temp
        } catch (error) {
          this.$func.log('-----Get Absent fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'no permission') {
            msg = '沒有權限'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.formData = []
          this.formItemTotal = 0
        } finally {
          this.tableLoading = false
        }
      }
    },
    async getStaffData() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          const data = {
            value: resultData.id,
            text: `${resultData.name} (${resultData.email})`,
          }
          temp.push(data)
        }

        this.staffList = temp
      } catch (error) {
        this.$func.log('-----Get Staff fail-----')
        this.$func.log(error)
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getAbsentData()
    },
    submitSearch() {
      if (this.$validate.DataValid(this.searchStaffs)) {
        this.formItemTotal = 0
        this.tablePage = 1
        this.formData = []
        this.getAbsentData()
      }
    },
    clearSearch() {
      this.formItemTotal = 0
      this.tablePage = 1
      this.formData = []
      this.searchStaffs = ''
      this.getAbsentData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getStaffData()
      this.getAbsentData()
    }
  },
}
</script>